import { SiteMapNode } from 'core-global-frontend-navigation';

export const siteMap = [
  {
    path: 'dashboard',
    resource: 'app-dashboard',
    showInNav: false,
    test: {
      expectedTextOnPage: [
        'Welcome back to RAMP Partner,'
      ]
    }
  },
  {
    label: 'Reports',
    path: 'reports',
    siteMapNodes: [
      {
        label: 'Domains',
        path: 'reports/domains',
        resource: 'app-reports-syndication',
        siteMapNodes: [
          {
            label: 'Account',
            resource: 'app-syndication-account',
            path: 'syndication/account',
            tableau: {
              path: 'PartnerSyndication-AccountSummary/AccountSummary',
              tokenType: 0,
            },
          },
          {
            label: 'Domain Performance Summary',
            resource: 'app-syndication-domain-performance-summary',
            path: 'syndication/domain-performance-summary',
            tableau: {
              path: 'PartnerSyndication-DomainSummary/DomainSummary',
              tokenType: 0,
            },
          },
          {
            label: 'Domain Performance Trending',
            resource: 'app-syndication-domain-performance-trending',
            path: 'syndication/domain-performance-trending',
            tableau: {
              path: 'PartnerSyndication-DomainTrending/DomainTrend',
              tokenType: 0,
            },
          },
          {
            label: 'Domain SubID Performance',
            resource: 'app-syndication-domain-sub-id-performance',
            path: 'syndication/domain-sub-id-performance',
            tableau: {
              path: 'PartnerSyndication-SubIdPerformance/SubIdPerformance',
              tokenType: 0,
            },
          },
          {
            label: 'Hourly and Intraday Pacing',
            resource: 'app-syndication-hourly',
            path: 'syndication/hourly-intraday-pacing',
            tableau: {
              path: 'PartnerSyndication-HourlyPerformance/MetricsbyHour',
              tokenType: 0,
            },
          },
          {
            label: 'Keyword',
            resource: 'app-syndication-keyword',
            path: 'syndication/keyword',
            tableau: {
              path: 'PartnerSyndication-Keywords/KeywordPerformance',
              tokenType: 0,
            },
          }
        ],
      },
      {
        label: 'Search',
        path: 'reports/search',
        resource: 'app-search-reports',
        siteMapNodes: [
          {
            label: 'Bing Hosted',
            path: 'search/bing-hosted',
            resource: 'app-search-bing-hosted',
            dashboardCard: {
              icon: '/assets/img/dashboard/domain-manager.svg',
              iconClassLabel: 'domain-manager',
              ctaLabel: 'View',
            },
            tableau: {
              path: 'SearchBingHosted/BingMetrics',
              tokenType: 1,
            },
          },
          {
            label: 'Bing Hosted Hourly',
            path: 'search/bing-hosted-hourly',
            resource: 'app-search-bing-hosted-hourly',
            tableau: {
              path: 'SearchBingHostedHourly/BingHourly',
              tokenType: 1,
            },
          },
          {
            label: 'Bing Hosted Sub-ID Level',
            path: 'search/bing-hosted-sub-id-level',
            resource: 'app-search-bing-hosted-sub-id-level',
            tableau: {
              path: 'SearchBingHostedSubID/BingSubID',
              tokenType: 1,
            },
          },
          {
            label: 'Bing PTQS',
            path: 'search/bing-ptqs',
            resource: 'app-search-bing-ptqs',
            tableau: {
              path: 'SearchBingPTQS/BingPTQS',
              tokenType: 1,
            },
          },
          {
            label: 'Partner Dashboard',
            resource: 'app-search-partner-dashboard',
            path: 'search/partner-dashboard',
            dashboardCard: {
              icon: '/assets/img/dashboard/domain-manager.svg',
              iconClassLabel: 'domain-manager',
              ctaLabel: 'View',
            },
            tableau: {
              path: 'SearchPartnerTrends/PartnerDashboard',
              tokenType: 1,
            },
          },
          {
            label: 'Sub-Id Performance',
            resource: 'app-search-top-level',
            path: 'search/sub-id-performance',
            tableau: {
              path: 'SearchSubIdPerformance/SubIdPerformance',
              tokenType: 1,
            },
          },
          {
            label: 'Top Keywords',
            resource: 'app-search-top-level',
            path: 'search/top-keywords',
            tableau: {
              path: 'SearchTopKeywords/TopKeywords',
              tokenType: 1,
            },
          },
          {
            label: 'Top Level',
            resource: 'app-search-top-level',
            path: 'search/top-level',
            dashboardCard: {
              icon: '/assets/img/dashboard/domain-manager.svg',
              iconClassLabel: 'domain-manager',
              ctaLabel: 'View',
            },
            tableau: {
              path: 'SearchPartnerSiteReport/PartnerSiteReport',
              tokenType: 1,
            },
          },
          {
            label: 'Yahoo Hosted',
            resource: 'app-search-yahoo-hosted',
            path: 'search/yahoo-hosted',
            dashboardCard: {
              icon: '/assets/img/dashboard/domain-manager.svg',
              iconClassLabel: 'domain-manager',
              ctaLabel: 'View',
            },
            tableau: {
              path: 'SearchYahooHosted/YahooMetrics',
              tokenType: 1,
            },
          },
          {
            label: 'Yahoo TQ Score',
            resource: 'app-search-yahoo-hosted',
            path: 'search/yahoo-tq-score',
            dashboardCard: {
              icon: '/assets/img/dashboard/domain-manager.svg',
              iconClassLabel: 'domain-manager',
              ctaLabel: 'View',
            },
            tableau: {
              path: 'SearchYahooTQScore/YahooTQScore',
              tokenType: 1,
            },
          },
        ],
      },
      {
        label: 'Related Search on Content',
        resource: 'app-syndication-rsoc-performance',
        path: 'syndication/search-on-content',
        siteMapNodes: [
          {
            label: 'RSoC Online Campaign Daily Metrics',
            path: 'syndication/rsoc-campaign-ids',
            resource: 'rsoc-campaign-ids',
            tableau: {
              path: 'RSoC-CampaignDailyPerformance/RSoC',
              tokenType: 0
            },
          },
          {
            label: 'RSoC Online Hourly Metrics',
            path: 'syndication/rsoc-campaign-ids-hourly',
            resource: 'rsoc-campaign-ids-hourly',
            tableau: {
              path: 'RSoC-CampaignHourlyPerformance/MetricsbyHour',
              tokenType: 0
            },
          },
          {
            label: 'RSoC Online Ad Daily Metrics',
            path: 'syndication/rsoc-ad-ids',
            resource: 'rsoc-ad-ids',
            tableau: {
              path: 'RsoC-AdIdDailyPerformance/RSoC',
              tokenType: 0
            },
          },
          {
            label: 'RSoC Online Keywords Metrics',
            path: 'syndication/rsoc-keywords',
            resource: 'rsoc-keywords',
            tableau: {
              path: 'RSoC-KeywordDailyPerformance/RSoCKeyword',
              tokenType: 0
            },
          }
        ]
      },
    ],
  },
  {
    label: 'Tools',
    path: 'tools',
    siteMapNodes: [
      {
        label: 'Domain Creator',
        resource: 'app-domain-creator',
        path: 'domain/create',
        description: 'Launch net new domains with us.',
        imgSrc: 'assets/img/dashboard/domain-creator.svg',
        dashboardCard: {
          icon: '/assets/img/dashboard/domain-creator.svg',
          iconClassLabel: 'domain-creator',
          ctaLabel: 'Create',
        },
      },
      {
        label: 'Domain Manager',
        resource: 'app-domain-manager',
        path: 'domain/manager',
        description: 'Manage your existing domains.',
        imgSrc: 'assets/img/dashboard/domain-manager.svg',
        dashboardCard: {
          icon: '/assets/img/dashboard/domain-manager.svg',
          iconClassLabel: 'domain-manager',
          ctaLabel: 'Manage',
        },
      },
      {
        label: 'RAMP.js Script Builder',
        path: 'rampjs/builder',
        resource: 'app-rampjs-builder',
        description:
          'Preview how your ads will render on your page and generate the embed code.',
        imgSrc: 'assets/img/rampjs-script-builder-image.svg',
      },
      {
        label: 'S2S Pixel Manager',
        path: 'pixel/manager',
        resource: 'app-pixel-manager',
        description: 'Setup S2S pixels and manage existing pixels.',
        imgSrc: 'assets/img/dashboard/pixel-manager.svg',
        dashboardCard: {
          icon: '/assets/img/dashboard/pixel-manager.svg',
          iconClassLabel: 'pixel-manager',
          ctaLabel: 'Manage',
        },
      },
      {
        label: 'Content Generator',
        path: 'content-generator',
        resource: 'app-content-generator',
        imgSrc: 'assets/img/dashboard/domain-manager.svg',
        embedded: {
          containerId: 'content-generator',
        }
      },
      {
        label: 'URL Builder',
        path: 'url-builder',
        resource: 'app-url-builder',
        imgSrc: 'assets/img/dashboard/domain-manager.svg',
        embedded: {
          containerId: 'url-builder',
        },
      },
    ],
  },
  {
    label: 'Docs',
    path: 'docs',
    title: 'Documentation',
    siteMapNodes: [
      {
        label: 'Campaign Domain URL Parameters',
        description: 'Campaign Domain URL Parameters',
        path: 'docs/campaign-domain-url-parameters',
        resource: 'docs-campaign-domain-url-parameters',
        title: 'Documentation',
        component: 'campaign-domain-url-parameters',
        defaultPage: true,
        showInNav: false,
        imgSrc: 'assets/img/documentation-image.svg',
        test: {
          expectedTextOnPage: [
            'On any System1 hosted campaign, you can add tracking and reporting attributes by passing URL parameters.'
          ]
        }
      },
      {
        label: 'Ad Network Pixel Tracking',
        description: 'Ad Network Pixel Tracking',
        title: 'Documentation',
        path: 'docs/ad-network-pixel-tracking',
        resource: 'docs-ad-network-pixel-tracking',
        component: 'ad-network-pixel-tracking',
        showInNav: false,
        imgSrc: 'assets/img/documentation-image.svg',
        test: {
          expectedTextOnPage: [
            'System1 supports adding conversion tracking pixels to your land'
          ]
        }
      },
      {
        label: 'API Endpoints',
        description: 'API Endpoints',
        title: 'Documentation',
        path: 'docs/syndication-api-endpoints',
        resource: 'docs-syndication-api-endpoints',
        component: 'syndication-api-endpoints',
        showInNav: false,
        test: {
          expectedTextOnPage: [
            'stem1 Reporting API provides access to data in your account. This document intends to provide a high level view of the API endpoints, however further details and input/output examples ca'
          ]
        }
      },
      {
        label: 'Reporting API for AFD Keyword Data',
        description: 'Reporting API for AFD Keyword Data',
        path: 'docs/reporting-api-for-afd-kw-data',
        title: 'Documentation',
        resource: 'docs-reporting-api-for-afd-kw-data',
        component: 'reporting-api-for-afd-kw-data',
        showInNav: false,
        test: {
          expectedTextOnPage: [
            'me to the System1 AFD Keyword Reporting API! This API is designed to provide you with easy, secure and timely access to data that you need to optimize your AFD Keyword mix.'
          ]
        }
      },
      {
        label: 'Bing Hosted API Endpoints',
        description: 'Bing Hosted API Endpoints',
        path: 'docs/reporting-api-for-bing-hosted',
        title: 'Documentation',
        resource: 'docs-reporting-api-for-bing-hosted',
        component: 'reporting-api-for-bing-hosted',
        showInNav: false,
        test: {
          expectedTextOnPage: [
            'lcome to the System1 Reporting API! This API is designed to provide you with easy, secure and timely access to the data that you need to manage your Bing Hoste'
          ]
        }
      },
      {
        label: 'Compliance Checklist',
        description: 'Compliance Checklist',
        path: 'docs/compliance-checklist',
        title: 'Documentation',
        resource: 'docs-compliance-checklist',
        component: 'compliance-checklist',
        showInNav: false,
        test: {
          expectedTextOnPage: [
            'The purpose of this checklist is to be a quick reference for high level requirements and not intended to be an exhaustive list.'
          ]
        }
      },
      {
        label: 'Video Walkthroughs',
        description: 'Video Walkthroughs',
        path: 'docs/app-video-walkthroughs',
        title: 'Documentation',
        resource: 'docs-app-video-walkthroughs',
        component: 'app-video-walkthroughs',
        showInNav: false
      },
      {
        label: 'Reference Material',
        description: 'Reference Material',
        path: 'docs/reference-material',
        title: 'Documentation',
        resource: 'docs-reference-material',
        component: 'reference-material',
        showInNav: false,
        test: {
          expectedTextOnPage: [
            ' support a number of dynamic values you can pass in a key'
          ]
        }
      },
      {
        label: 'SEM API Endpoints',
        description: 'SEM API Endpoints',
        path: 'docs/search-api-endpoints',
        title: 'Documentation',
        resource: 'docs-search-api-endpoints',
        component: 'search-api-endpoints',
        showInNav: false,
        test: {
          expectedTextOnPage: [
            'The System1 Reporting API provides access to data in your account. T'
          ]
        }
      },
      {
        label: 'Embedded Search SDK',
        description: 'Embedded Search SDK',
        title: 'Documentation',
        path: 'docs/embedded-search',
        resource: 'docs-embedded-search',
        component: 'embedded-search',
        showInNav: false,
        test: {
          expectedTextOnPage: [
            'A segment is the identifier used in embedded-search to identify a buc'
          ]
        }
      },
      {
        label: 'SEM Guidelines',
        description: 'SEM Guidelines',
        title: 'Documentation',
        path: 'docs/sem',
        resource: 'docs-sem',
        component: 'sem',
        showInNav: false,
        test: {
          expectedTextOnPage: [
            `Must contain the word 'search' or 'find' (or translation of tha`
          ]
        }
      },
    ],
  },
  {
    label: 'Need Help?',
    path: 'need-help',
    resource: 'app-tickets',
    siteMapNodes: [
      {
        label: 'Send Feedback',
        resource: 'app-tickets',
        url: 'https://ramp-partner.typeform.com/to/oGKoIAzd',
      },
    ],
  },
] as SiteMapNode[];
